import { Filter,required,WithListContext, useGetList, ReferenceInput, SelectInput, List, Datagrid, NumberField, FunctionField, TextField, useStore } from "react-admin";
import { Typography } from '@mui/material';
import { PostPagination } from './Trainee';
import { DepartmentFilterForm } from "./Department";
import { TableHead, TableRow, TableCell, TextField as muiTextField } from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip,Legend } from 'recharts';

const validateStartDate = (values) => {
    const errors = {};

    if (!values) {
        errors.rotation = "Must select a rotation"
    }
    return errors
}
 
const DepartmentStatisticsFilter = (props) => {
    const { isLoading, data } = useGetList('rotations');
    data?.sort((a, b) => (a.startDate > b.startDate) ? 1 : ((b.startDate > a.startDate) ? -1 : 0));
    const currentCohorts = data?.filter((rotation) => (rotation.cohortsIndex <= 11 && rotation.visible));
    const currentRotation = currentCohorts?.at(-1)
    return (
        <Filter {...props}>
    <ReferenceInput label="Rotation" reference="rotations" filter={{ rotationsByTypeAndStartDate: { type: "Rotation" } }} source="departmentStatisticsByRotationIDAndDepartmentName.rotationID" alwaysOn>
        <SelectInput label="Rotation Date" defaultValue={currentRotation?.id} validate={required()}/>
    </ReferenceInput>
    </Filter>)
};

const DatagridHeader = () => {
    return (
        <TableHead >
            <TableRow>
                <TableCell key={"departmentID"}>
                    <Typography sx={{ fontWeight: 'bold', fontSize: 14 }}>{"Department"} </Typography>
                </TableCell>
                {process.env.REACT_APP_MULTISITE == "1" &&
                    <TableCell key={"siteID"}>
                        <Typography sx={{ fontWeight: 'bold', fontSize: 14 }}>{"Office"} </Typography>
                    </TableCell>}
                <TableCell key={"numberOfFirstYearsAssigned"}>
                    <Typography sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: 14 }} >{"Trainees assigned"} </Typography>
                    <Typography sx={{ textAlign: 'center', fontStyle: 'italic', fontSize: 14 }} >{"First year / Second year"} </Typography>
                </TableCell>
                <TableCell key={"numberOfSeatsAvailable"}>
                    <Typography sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: 14 }} >{"Seats"} </Typography>
                    <Typography sx={{ textAlign: 'center', fontStyle: 'italic', fontSize: 14 }} >{"Available (Assigned)"} </Typography>
                </TableCell>
                <TableCell key={"totalPrefrences"}>
                    <Typography sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: 14 }} >{"Preferences requested"} </Typography>
                </TableCell>
                <TableCell key={"numberOfFirstPreferences"}>
                    <Typography sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: 14 }} >{"1st Preferences"} </Typography>
                    <Typography sx={{ textAlign: 'center', fontStyle: 'italic', fontSize: 14 }} >{"Requested (Assigned)"} </Typography>
                </TableCell>
                <TableCell key={"numberOfSecondPreferences"}>
                    <Typography sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: 14 }} >{"2nd Preferences"} </Typography>
                    <Typography sx={{ textAlign: 'center', fontStyle: 'italic', fontSize: 14 }} >{"Requested (Assigned)"} </Typography>
                </TableCell>
                <TableCell key={"numberOfThirdPreferences"}>
                    <Typography sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: 14 }} >{"3rd Preferences"} </Typography>
                    <Typography sx={{ textAlign: 'center', fontStyle: 'italic', fontSize: 14 }} >{"Requested (Assigned)"} </Typography>
                </TableCell>
                <TableCell key={"numberOfFourthPreferences"}>
                    <Typography sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: 14 }} >{"4th Preferences"} </Typography>
                    <Typography sx={{ textAlign: 'center', fontStyle: 'italic', fontSize: 14 }} >{"Requested (Assigned)"} </Typography>
                </TableCell>
                <TableCell key={"numberOfFifthPreferences"}>
                    <Typography sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: 14 }} >{"5th Preferences"} </Typography>
                    <Typography sx={{ textAlign: 'center', fontStyle: 'italic', fontSize: 14 }} >{"Requested (Assigned)"} </Typography>
                </TableCell>
                <TableCell key={"numberOfSixthPreferences"}>
                    <Typography sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: 14 }} >{"6th Preferences"} </Typography>
                    <Typography sx={{ textAlign: 'center', fontStyle: 'italic', fontSize: 14 }} >{"Requested (Assigned)"} </Typography>
                </TableCell>
                <TableCell key={"numberOfNoPreferencesAssigned"}>
                    <Typography sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: 14 }} >{"No Preferences"} </Typography>
                    <Typography sx={{ textAlign: 'center', fontStyle: 'italic', fontSize: 14 }} >{"(Assigned)"} </Typography>
                </TableCell>
                <TableCell key={"numberOfManualOverrides"}>
                    <Typography sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: 14 }}>{"Manual Overrides"} </Typography>
                </TableCell>
            </TableRow>
        </TableHead>
    )
};

export const DepartmentStatisticsList = () => {
    const { isLoading, data } = useGetList('rotations');
    data?.sort((a, b) => (a.startDate > b.startDate) ? 1 : ((b.startDate > a.startDate) ? -1 : 0));
    const currentCohorts = data?.filter((rotation) => (rotation.cohortsIndex <= 11 && rotation.visible));
    const currentRotation = currentCohorts?.at(-1)


    if (!isLoading) {
        return (
            <List resource="departmentStatistics" pagination={<PostPagination />} filters={<DepartmentStatisticsFilter/>} actions={false} empty={false} perPage={100} >
                        <WithListContext
                    render={({ data }) => (
                        <BarChart width={1500} height={700} data={data} margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 200,
                          }}>
                            <XAxis dataKey="departmentName" angle={-45} textAnchor="end" interval={0}/>
                            <YAxis />
                            <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
                            <Tooltip />
                            <Legend verticalAlign="top"/>
                            <Bar dataKey="numberOfFirstPreferences" stackId="a" fill="#8884d8" name="First Preferences"/>
                            <Bar dataKey="numberOfSecondPreferences" stackId="a" fill="#82ca9d" name="Second Preferences"/>
                            <Bar dataKey="numberOfThirdPreferences" stackId="a" fill="#ffc658" name="Third Preferences"/>
                            <Bar dataKey="numberOfFourthPreferences" stackId="a" fill="#FF8042" name="Fourth Preferences"/>
                            <Bar dataKey="numberOfFifthPreferences" stackId="a" fill="red" name="Fifth Preferences"/>
                            <Bar dataKey="numberOfSixthPreferences" stackId="a" fill="pink" name="Sixth Preferences"/>
                            <Bar dataKey="numberOfFirstPreferencesAssigned" stackId="b" fill="#8884d8" name="First Preferences Assigned"/>
                            <Bar dataKey="numberOfSecondPreferencesAssigned" stackId="b" fill="#82ca9d" name="Second Preferences Assigned"/>
                            <Bar dataKey="numberOfThirdPreferencesAssigned" stackId="b" fill="#ffc658" name="Third Preferences Assigned"/>
                            <Bar dataKey="numberOfFourthPreferencesAssigned" stackId="b" fill="#FF8042" name="Fourth Preferences Assigned"/>
                            <Bar dataKey="numberOfFifthPreferencesAssigned" stackId="b" fill="red" name="Fifth Preferences Assigned"/>
                            <Bar dataKey="numberOfSixthPreferencesAssigned" stackId="b" fill="pink" name="Sixth Preferences Assigned"/>
                            <Bar dataKey="numberOfNoPreferencesAssigned" stackId="b" fill="black" name="No Preferences Assigned"/>
                            {/* <Bar dataKey="numberOfFirstYearsAssigned" stackId="c" fill="cyan" name="First Years Assigned"/>
                            <Bar dataKey="numberOfSecondYearsAssigned" stackId="c" fill="magenta" name="Second Years Assigned"/> */}
                            {/* <Bar dataKey="numberOfSeatsAvailable" stackId="d" fill="gray" name="Seats Available"/> */}
                        </BarChart>
                    )}
                />
                <WithListContext
                    render={({ data }) => (
                        <BarChart width={1500} height={700} data={data} margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 200,
                          }}>
                            <XAxis dataKey="departmentName" angle={-45} textAnchor="end" interval={0}/>
                            <YAxis />
                            <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
                            <Tooltip />
                            <Legend verticalAlign="top"/>

                            <Bar dataKey="numberOfFirstYearsAssigned" stackId="a" fill="cyan" name="First Years Assigned"/>
                            <Bar dataKey="numberOfSecondYearsAssigned" stackId="a" fill="magenta" name="Second Years Assigned"/>
                               <Bar dataKey="numberOfSeatsAvailable" stackId="b" fill="gray" name="Seats Available"/>

                        </BarChart>
                    )}
                />
            </List>
        );
    }
}
